@import 'react/constants.scss';

.inputWrapper {
  display: flex;
  @media (max-width: $tablet) {
    display: block;
  }
  align-items: center;
  margin-bottom: 13px;
}

.label {
  width: 40%;
  @media (max-width: $tablet) {
    width: 100%;
  }
  white-space: nowrap;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  flex-grow: 1;
  color: #a2a6b0;

  &.error {
    color: $red;
  }
}

.input {
  width: 100%;
  background: white;
  color: $darkGray;
  border: 1px solid #c6cad3;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 18px 16px;
  outline: none;
  flex-grow: 1.25;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  &:focus {
    border-color: $primary;
    box-shadow: 0 0 3px $primaryLight;
  }

  &.error, &.error:focus {
    border-color: $red;
    box-shadow: 0 0 3px $red;
  }

  &::placeholder {
    font-style: normal;
    font-weight: 400;
    color: #838895;
  }
}

.label + .input {
  width: 60%;

  @media (max-width: $tablet) {
    width: 100%;
  }
}
.errorMessage {
  color: $red;
  font-size: 14px;
}
