@import 'react/constants.scss';

.container {
  display: flex;
  flex-flow: wrap;
  & > * {
    flex: 1;
    min-height: 100vh;
  }
}

.leftBlock {
  position: relative;
  overflow: hidden;
  @media screen and (max-width: $tablet) {
    display: none;
  }
  .background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url(./assets/logo-room.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -webkit-animation: zoomin 25s linear forwards;
    animation: zoomin 25s linear forwards;
  }
  div:last-child {
    position: relative;
    @include flex(column, (justify-content: center));
    background-color: rgba(34, 34, 34, 0.5);
    height: 100%;
    img {
      width: 300px;
    }
  }

  @-webkit-keyframes zoomin {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.3);
    }
  }
}

.rightBlock {
  background: #FFFFFF;

  @include flex(column);
  & > div:first-child {
    @include flex(column, (justify-content: flex-start));
    margin: auto;
    padding: 15px;
  }

  .header {
    text-align: center;
  }

  img {
    max-width: 90px;
    margin: 0 auto;
  }
  h1 {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    text-align: center;
    margin: 20px 0 15px;
    color: #4C4C53;
  }
  span {
    color: $mediumGray;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }


  .form {
    max-width: 460px;
    width: 100%;
    margin-top: 56px;
    .form-group {
      margin-bottom: 20px;
      .error-text {
        font-size: 12px;
        margin-left: 25px;
        margin-top: 5px;
        line-height: normal;
        color: rgba($color: red, $alpha: 0.5);
      }
    }

    button {
      height: 60px;
      width: 100%;
      margin-bottom: 20px;
    }
    .error-text {
      font-size: 12px;
      margin-left: 25px;
      margin-top: 5px;
      line-height: normal;
      color: rgba($color: red, $alpha: 0.5);
    }
  }



  .footer {
    @include flex(column);
    flex: 1;
    justify-content: flex-end;
    margin-bottom: 40px;
    a {
      cursor: pointer;
      color: $primary;
      font-weight: 600;
      text-decoration: none;
    }
    .separator {
      margin-top: 15px;
      position: relative;
      width: 100%;
      &::before, &::after {
        content: "";
        position: absolute;
        height: 1px;
        width: 45%;
        top: 12.5px;
        background-color: rgba($inactiveGray, 0.5);
      }
      &::before {
        left: 0;
      }
      &::after {
        right: 0;
      }
    }
    button {
      cursor: pointer;
      background-color: $inactiveGray;
      border-radius: 5px;
      border-width: 0;
      padding: 15px 40px;
      color: white;
      font-size: 16px;
      margin-top: 15px;
    }
  }
}
