/*
 * Copyright (C) 2016 OpenMotics BV
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

/* OpenMotics */

.outline-alive{
    color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #00a65a;
}

.outline-dead{
    color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: red;
}

.outline-undefined{
    color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: gray;
}

@-webkit-keyframes bounce {
    0% { top: 0; }
    50% { top: -0.2em; }
    70% { top: -0.3em; }
    100% { top: 0; }
}

@-moz-keyframes bounce {
    0% { top: 0; }
    50% { top: -0.2em; }
    70% { top: -0.3em; }
    100% { top: 0; }
}

@-o-keyframes bounce {
    0% { top: 0; }
    50% { top: -0.2em; }
    70% { top: -0.3em; }
    100% { top: 0; }
}

@-ms-keyframes bounce {
    0% { top: 0; }
    50% { top: -0.2em; }
    70% { top: -0.3em; }
    100% { top: 0; }
}

@keyframes bounce {
    0% { top: 0; }
    50% { top: -0.2em; }
    70% { top: -0.3em; }
    100% { top: 0; }
}

.blinker{
    animation: blinker 1s linear 1;
}

@keyframes blinker {
    50% { opacity: 0; }
}

.actionicon {
    opacity: 0.3;
    filter: alpha(opacity=40);
    color: #333333;
}

.actionicon:hover {
    opacity: 1.0;
    filter: alpha(opacity=100);
}

.hand {
    cursor: pointer;
}

.unset {
    float: unset;
}

.italic {
    font-style: italic;
}

.clear-both {
    clear: both;
}

.energy-large {
    font-size: 25px;
}

.energy-small {
    font-size: 12px;
}

.is-long > .toggle {
    min-width: 110px !important;
}

.bell {
    color: #f39c12;
    -webkit-animation: ring 4s .7s ease-in-out infinite;
    -webkit-transform-origin: 50% 4px;
    -moz-animation: ring 4s .7s ease-in-out infinite;
    -moz-transform-origin: 50% 4px;
    animation: ring 4s .7s ease-in-out infinite;
    transform-origin: 50% 4px;
  }

  .notifier {
    color: #f39c12;

  }

  .dropdown-footer > a {
    font-size: 12px;
    padding: 7px 10px !important;
    border-bottom: 1px solid #eeeeee;
    color: #444 !important;
    text-align: center;
  }

  @-webkit-keyframes ring {
    0% { -webkit-transform: rotateZ(0); }
    1% { -webkit-transform: rotateZ(30deg); }
    3% { -webkit-transform: rotateZ(-28deg); }
    5% { -webkit-transform: rotateZ(34deg); }
    7% { -webkit-transform: rotateZ(-32deg); }
    9% { -webkit-transform: rotateZ(30deg); }
    11% { -webkit-transform: rotateZ(-28deg); }
    13% { -webkit-transform: rotateZ(26deg); }
    15% { -webkit-transform: rotateZ(-24deg); }
    17% { -webkit-transform: rotateZ(22deg); }
    19% { -webkit-transform: rotateZ(-20deg); }
    21% { -webkit-transform: rotateZ(18deg); }
    23% { -webkit-transform: rotateZ(-16deg); }
    25% { -webkit-transform: rotateZ(14deg); }
    27% { -webkit-transform: rotateZ(-12deg); }
    29% { -webkit-transform: rotateZ(10deg); }
    31% { -webkit-transform: rotateZ(-8deg); }
    33% { -webkit-transform: rotateZ(6deg); }
    35% { -webkit-transform: rotateZ(-4deg); }
    37% { -webkit-transform: rotateZ(2deg); }
    39% { -webkit-transform: rotateZ(-1deg); }
    41% { -webkit-transform: rotateZ(1deg); }

    43% { -webkit-transform: rotateZ(0); }
    100% { -webkit-transform: rotateZ(0); }
  }

  @-moz-keyframes ring {
    0% { -moz-transform: rotate(0); }
    1% { -moz-transform: rotate(30deg); }
    3% { -moz-transform: rotate(-28deg); }
    5% { -moz-transform: rotate(34deg); }
    7% { -moz-transform: rotate(-32deg); }
    9% { -moz-transform: rotate(30deg); }
    11% { -moz-transform: rotate(-28deg); }
    13% { -moz-transform: rotate(26deg); }
    15% { -moz-transform: rotate(-24deg); }
    17% { -moz-transform: rotate(22deg); }
    19% { -moz-transform: rotate(-20deg); }
    21% { -moz-transform: rotate(18deg); }
    23% { -moz-transform: rotate(-16deg); }
    25% { -moz-transform: rotate(14deg); }
    27% { -moz-transform: rotate(-12deg); }
    29% { -moz-transform: rotate(10deg); }
    31% { -moz-transform: rotate(-8deg); }
    33% { -moz-transform: rotate(6deg); }
    35% { -moz-transform: rotate(-4deg); }
    37% { -moz-transform: rotate(2deg); }
    39% { -moz-transform: rotate(-1deg); }
    41% { -moz-transform: rotate(1deg); }

    43% { -moz-transform: rotate(0); }
    100% { -moz-transform: rotate(0); }
  }

  @keyframes ring {
    0% { transform: rotate(0); }
    1% { transform: rotate(30deg); }
    3% { transform: rotate(-28deg); }
    5% { transform: rotate(34deg); }
    7% { transform: rotate(-32deg); }
    9% { transform: rotate(30deg); }
    11% { transform: rotate(-28deg); }
    13% { transform: rotate(26deg); }
    15% { transform: rotate(-24deg); }
    17% { transform: rotate(22deg); }
    19% { transform: rotate(-20deg); }
    21% { transform: rotate(18deg); }
    23% { transform: rotate(-16deg); }
    25% { transform: rotate(14deg); }
    27% { transform: rotate(-12deg); }
    29% { transform: rotate(10deg); }
    31% { transform: rotate(-8deg); }
    33% { transform: rotate(6deg); }
    35% { transform: rotate(-4deg); }
    37% { transform: rotate(2deg); }
    39% { transform: rotate(-1deg); }
    41% { transform: rotate(1deg); }

    43% { transform: rotate(0); }
    100% { transform: rotate(0); }
  }

@media (min-width: 767px) {
    .flex-group {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
    }

    .form-horizontal .flex-group .control-label {
        padding-top: 0;
    }
}

.control-margin-right {
    margin-right: 15px;
}

@media (max-width: 767px) {
    .control-margin-right {
        margin-right: 5px;
    }

    .skin-green .main-header .navbar .dropdown-menu li a {
        color: inherit !important;
    }
}

.om-splash-logo {
    width: 200px;
    height: 141px;
}

.spinner-logo {
    animation: spin 4s ease-out infinite;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.nowrap {
    white-space: nowrap;
}

.callout-small {
    margin-bottom: 0 !important;
    padding: 7px 15px !important;
}

.file-upload {
    position: relative;
    overflow: hidden;
    margin: 0 10px 10px 10px;
}

.file-upload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

.btn-mini {
    padding: 1px 5px;
    font-size: 12px !important;
    line-height: 1 !important;
    border-radius: 3px;
}

.no-padding {
    padding: 0 !important;
}

.label-padding {
    padding-top: 7px !important;
}

.flex {
    display: flex;
    align-items: center;
}

.footer:not(li) {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 20px;
}

/* noUISlider */
.noUi-target {
    height: 6px !important;
    margin: 15px 15px 30px 15px;
}

@media (min-width: 768px) {
    .noUi-handle {
        top: -10px !important;
    }

    .noUi-handle:after {
        left: 8px !important;
    }

    .noUi-handle:before, .noUi-handle:after {
        height: 10px !important;
        left: 5px !important;
        top: 4px !important;
    }

    .noUi-handle:after {
        left: 8px !important;
    }

    .noUi-horizontal .noUi-handle {
        width: 16px !important;
        height: 20px !important;
        right: -8px !important;
        top: -8px !important;
    }
}
@media (max-width: 767px) {
    .noUi-horizontal .noUi-handle {
        top: -13px !important;
    }
}

.noUi-connect {
    background-color: #eaeaea !important;
}

.noUi-connect.active {
    background-color: #00a65a !important;
}

.noUi-pips-horizontal {
    font-size: 10px;
    padding: 5px 0 !important;
}

.noUi-marker-horizontal.noUi-marker {
    width: 1px !important;
}

.noUi-marker-horizontal.noUi-marker-large {
    height: 8px !important;
}

.noUi-marker-horizontal.noUi-marker-normal {
    height: 4px !important;
}

.noUi-value-horizontal {
    -webkit-transform: translate(-50%, 50%) !important;
    transform: translate(-50%, 50%) !important;
}

.fg-red {
    color: #dd4b39;
}

.as-footer {
    padding-top: 0 !important;
    margin-top: -15px !important;
}

/* AdminLTE */
.main-header {
    z-index: 999 !important;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
    background-color: #00a65a !important;
}

.btn.btn-app {
    margin-right: 5px;
}

.btn.btn-app .btn-content {
    max-width: 70px;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.btn.btn-app .btn-content .fa {
    display: block;
    font-size: 20px;
}

.inputs-page .btn.btn-app span.input-label, .thermostats-block .btn.btn-app span.thermostats-label {
    max-width: 70px;
    width: 70px;
    overflow: hidden;
}

/* Aurelia */
ux-dialog-overlay.active {
    background-color: black;
    opacity: .5 !important;
}

ux-dialog-container.active > div {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

/* TUI Calendar */
.tui-full-calendar-time-schedule {
    min-height: 19px;
}

.nested-option-icon-arrow-fix {
    margin-right: 10px;
}

.navbar-static-top {
    display: flex;
    align-items: center;
}

.center-logo {
    display: flex;
    align-items: center;
}

.content, .content-header {
    padding-left: 30px;
    padding-right: 30px;
}

.content-wrapper {
    min-height: calc(100vh - var(--header-height)) !important;
    background-color: #F8F8FA;
}

.content-wrapper {
    margin-left: 281px;
    overflow-y: auto;
    height: 100%;
    padding-bottom: 40px;
}

.main-footer {
    margin-left: 0;
    position: absolute;
    bottom: 0;
    width: calc(100% - 281px);
}

.content-wrapper, .main-footer {
    transition: transform .3s ease-in-out,margin .3s ease-in-out;
    transition-duration: 400ms;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: width,margin-left;
}

:root {
    --header-height: 71px;
}

.content-container {
    min-height: calc(100vh - var(--header-height));
    max-height: calc(100vh - var(--header-height));
    height: calc(100vh - var(--header-height));
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow: hidden;
}

#react-navigation-root {
    position: absolute;
    height: 100%;
}

* {
    font-family: 'Inter', sans-serif;
}

.wrapper {
    background-color: #ecf0f5;
}


@font-face {
    font-family: Inter;
    src: url(./fonts/Inter-Bold.ttf) format("opentype");
    font-weight: 700;
}

@font-face {
    font-family: Inter;
    src: url(./fonts/Inter-Medium.ttf) format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: Inter;
    src: url(./fonts/Inter-Regular.ttf) format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: Inter;
    src: url(./fonts/Inter-SemiBold.ttf) format("opentype");
    font-weight: 600;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('./fonts/RobotoMono-Regular.ttf') format('opentype');
    font-weight: 400;
}


::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
    -webkit-border-radius:100px
}

::-webkit-scrollbar:hover {
    background-color:rgba(0, 0, 0, .09)
}

::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .5);
    -webkit-border-radius:100px
}

::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, .61);
    -webkit-border-radius:100px
}

