@import "react/constants";

$borderRadius: 5px;

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.current {
  color: $darkGray;
  padding-right: 13px;
  font-weight: 500;
}

.popup {
  background: #FFF;
  box-shadow: 0 7px 40px rgba(0, 0, 0, 0.2);
  border-radius: $borderRadius;
  overflow: auto;
}

.popup.hasExtra {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.extra {
  background: #FFF;
  box-shadow: 0 7px 40px rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: $borderRadius;
  border-bottom-right-radius: $borderRadius;
  padding: 0 16px;
}

.option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 48px;
  height: 48px;
  padding: 0 16px;
  text-align: left;
  text-decoration: none;
  max-width: 100%;
  position: relative;
  cursor: pointer;
  outline: none;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  -webkit-tap-highlight-color: transparent;

  &.none {
    background-color: #f8f8fA;
  }
}

.option:hover {
  background: #F8F8FA;
}
