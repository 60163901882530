@import 'react/constants.scss';

.notification {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 350px;
  width: 100%;
  padding: 12px 16px;
  gap: 16px;

  &.success {
    background-color: #f5fdf3;
    .icon {
      background-color: #00234726;
      svg {
        width: 20px;
        height: 20px;
        path {
          fill: $primary;
        }
      }
    }
  }
  &.danger {
    background-color: #fff6f9;
    .icon {
      background-color: #f33b7826;
      svg {
        width: 20px;
        height: 20px;
        path {
          fill: #f33b78;
        }
      }
    }
  }
  &.info {
    background-color: #eef5fc;
    .icon {
      background-color: #529ee426;
      svg {
        width: 20px;
        height: 20px;
        path {
          fill: #529ee4;
        }
      }
    }
  }
  &.warning {
    background-color: #fff8f0;
    .icon {
      background-color: #ffaf3126;
      svg {
        width: 20px;
        height: 20px;
        path {
          fill: #ffaf31;
        }
      }
    }
  }
}
.icon {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 40px !important;
  width: 40px !important;
  height: 40px !important;
}
.content {
  width: auto;
}
.action {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 40px;
  svg {
    width: 16px;
    height: 16px;
  }
}
.title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: $darkGray;
}
.message {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: $darkGray;
}
