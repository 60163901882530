@import 'react/constants.scss';

.button {
  @include flex(row, (justify-content: center));
  @media (max-width: $tablet) {
    display: block;
  }
  box-sizing: border-box;
  position: relative;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  margin: 0;
  @media (max-width: $tablet) {
    margin-bottom: 10px;
  }
  min-width: 64px;
  width: 210px;
  height: 50px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  border-radius: 5px;
  overflow: visible;
  color: inherit;
}

.primary {
  background: linear-gradient(161.34deg, $primary 7.87%, $primaryLight 92.8%);
  color: #fff;

  &:disabled {
    background: linear-gradient(90deg, rgba(0, 35, 71, 0.7) 0%, rgba(0, 67, 142, 0.7) 100%);
  }
}

.secondary {
    background-color: #c6cad3;
    color: #fff;
}
