@import 'react/constants.scss';

.messageContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 10px;
    column-gap: 10px;
}
.errorMessage {
    color: $red;
    font-size: 13px;
    span {
        display:inline;
    }
}
