@import 'react/constants';

.form {
  .error {
    font-size: 12px;
    margin-left: 25px;
    margin-top: 5px;
    line-height: normal;
    color: rgba($color: red, $alpha: 0.5);
  }
}

.forgotten {
  font-size: 12px;
  text-align: right;
  margin-top: -15px;
  margin-bottom: 15px;
  color: $mediumGray;
  a {
    color: $mediumGray;
    text-decoration: underline;
  }
}
