@import 'react/constants';

.background {
  background-image: url('./background.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.mobileBackground {
  display: none;
}

.langSwitch {
  position: absolute;
  right: 34px;
  top: 32px;

  & > div > span {
    color: #FFF;
  }
}

.centered {
  width: 720px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  border-radius: 12px;
  background: #FFF;
  width: 100%;
  padding: 64px 40px 48px;

  h1 {
    color: #1D1B33;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
  }

  p {
    color: #1D1B33;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  a {
    color: #002855;
    font-weight: 600;
  }
}

.actions {
  padding: 32px 20px;
  border-radius: var(--rd-m, 12px);
  background: linear-gradient(90deg, rgba(1, 63, 132, 0.12) 0%, rgba(126, 212, 53, 0.12) 100%);
  @include flex(row, (justify-content: center, gap: 40px));

  & button {
    width: 100%;
  }

  .action {
    text-align: center;
    width: 100%;

    .logo {
      height: 40px;
      margin-bottom: 24px;
    }
  }
}

.openmoticsLogo {
  text-align: center;
  img {
    margin-right: 8px;
  }

  color: #1D1B33;
  font-size: 18px;
  font-weight: 600;
}

.btnRenson {
  background: linear-gradient(91deg, #002855 0%, #00438E 100%);
}

.btnOpen {
  background: linear-gradient(47deg, #34B520 0%, #A0E23E 100%);
}

.wrapPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  background: rgba(29, 27, 51, 0.40);
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0px 4px 150px 0px rgba(0, 0, 0, 0.10);
  border-radius: 10px;
  padding: 48px 40px;
  z-index: 999;
  max-width: 560px;
  text-align: center;

  .close {
    position: absolute;
    top: 16px;
    right: 16px;
    svg {
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
  }

  p {
    text-align: left;
  }

  .gotIt {
    border-radius: 12px;
    border: 1px solid #C8CDD8;
    background: transparent;
    padding: 14px;
    margin-top: 40px;
    width: 100%;
  }
}

@include tablet {
  .centered {
    width: 100%;
    .content {
      margin-left: 72px;
      margin-right: 72px;
    }
  }
}

@include mobile {
  .background {
    background: none;
    display: flex;
    flex-direction: column;
  }
  .mobileBackground {
    display: unset;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-image: url('./background.jpg');
  }

  .wrapPopup {
    background: transparent;
  }
  .popup {
    position: fixed;
    top: 228px;
    left: 0;
    width: 100%;
    height: calc(100% - 228px);
    transform: unset;
    background-color: white;
    z-index: 999;
    max-width: 560px;
  }

  .langSwitch {
    position: absolute;
    top: calc(34px + 170px);

    & > div > span {
      color: #1D1B33;
    }
    & > div > svg path {
      fill: #1D1B33;
    }
  }

    .centered {
    align-items: flex-end;

    .content {
      margin-left: 0;
      margin-right: 0;
      border-radius: 16px 16px 0 0;
      padding: 64px 20px 48px;

      h1 {
        font-size: 24px;
        line-height: 36px;
      }
    }
  }

  .actions {
    background: linear-gradient(180deg, rgba(1, 63, 132, 0.12) -2.53%, rgba(126, 212, 53, 0.12) 100%);
    flex-direction: column;
    gap: 24px;
  }
}