/* Primary colors */
$primary: #002347;
$primaryLight: #00438E;
$thermostatPrimary: #F57C3F;
$thermostatSecondary: #f5a13f;
$outletPrimary: #00a8b1;
$outletSecondary: #00b29c;
$ventilationPrimary: #48B7E3;
$ventilationSecondary: #52b2e4;
$scenePrimary: #F33B78; 
$sceneSecondary: #ee4d82;
$shutterPrimary: #6343FF;
$shutterSecondary: #9171eb;
$shutterAction: #7852E4;
$secondaryGreen: #1EB300;
$darkText: #2B3043;
$darkTextGray: #2E2E3A;
$darkDelimiter: #20283a;

/* Control Backgrounds */
$shutterBackground: rgba($shutterPrimary, 0.161);
$ventilationBackground: rgba($ventilationPrimary, 0.102);
$sceneBackground: rgba($scenePrimary, 0.161);

/* Gradients */
$ventilationsGradient: linear-gradient(90deg, #52A1E4 0%, #52B2E4 100%);

/* Gray shades */
$inactiveGray: #c6cad3;
$darkGray: #4c4c53;
$mediumGray: #7a7f8d;
$lightGray: #a9aeb9;
$lightSlateGray: #818693;
$lightGrayBorder: #E6E6EC;
$secondaryGray: #969ba5;
$boxesGray: #EBEBF0;
$boxesDarkGray: #484852;
$textDarkGrey: #2E2E3A;

$textSecondary: #979591;
$iconSecondary: #96928D;

/* Additional colors */
$yellow: #fec637;
$secondaryOrange: #f9b295;
$secondaryBlue: #529ee4;
$red: #e83238;
$speechRed: #d33b3b;
$whiteSmoke: #f1f1f1;
$darkGreen: #34B520;
$lightGreen: #A0E23E;
$clearWhite: #FFF6F9;

$border-color: rgba($lightGray, 0.2);

$primary-background-color: linear-gradient(90deg, $primary 0%, $primaryLight 100%);

/* Window sizes */
$tablet: 1134px;
$mobile: 550px;

@mixin title($weight: 600, $args: ()) {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: $weight;
  font-size: 34px;
  line-height: 41px;
  margin: 0;
  @each $property, $value in $args {
    #{$property}: $value;
  }
}

@mixin syntax-colors($args...) {
  // (string: #080, comment: #800, $variable: $60b)

  @each $name, $color in meta.keywords($args) {
    pre span.stx-#{$name} {
      color: $color;
    }
  }
}

@mixin flex($direction: row, $args: ()) {
  display: flex;
  flex-direction: $direction;
  align-items: center;
  @each $property, $value in $args {
    #{$property}: $value;
  }
}

@mixin card($padding: 16px, $args: ()) {
  background: #fff;
  box-shadow: 0 5px 20px 0  rgba($mediumGray, 0.08);
  border-radius: 4px;
  padding: $padding;
  @each $property, $value in $args {
    #{$property}: $value;
  }
}

@mixin tablet {
  @media screen and (max-width: $tablet) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $mobile) {
    @content;
  }
}

.subtitle {
  font-weight: 600;
  margin-right: 60px;
  font-size: 24px;
  margin-top: 60px;
  @include mobile {
    font-size: 18px;
  }
}
