@import 'react/constants.scss';

.passwordWrapper {
  position: relative;
  display: flex;
  @media (max-width: $tablet) {
    display: block;
  }
  align-items: center;
  margin-bottom: 13px;

  img {
    cursor: pointer;
    position: absolute;
    right: 10px;
    @media (max-width: $tablet) {
      top: 48px;
    }
  }
}

.label {
  width: 40%;
  @media (max-width: $tablet) {
    width: 100%;
  }
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  flex-grow: 1;
  color: #a2a6b0;
}

.input {
  width: 100%;
  background: white;
  color: #a9aeb9;
  border: 1px solid #c6cad3;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 18px 41px 19px 16px;
  outline: none;
  flex-grow: 1.25;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  &:focus {
    border-color: $primary;
    box-shadow: 0 0 3px $primary;
  }

  &::placeholder {
    font-style: normal;
    font-weight: 400;
    color: #838895;
  }
}

.label + .input {
  width: 60%;

  @media (max-width: $tablet) {
    width: 100%;
  }
}
